// import Store from "@/store";
import store from "../store";
import { consoleLog } from "./logger";

const redirectToCompany = () => {
  if (store.getters.getRedirectUrl) {
    if (typeof store.getters.getRedirectUrl == "string") {
      let url = store.getters.getRedirectUrl;
      if (url.includes("@")) {
        url = url.split("@")[0];
      }
      consoleLog("RedirectToCompany-string ", store.getters.url);
      window.location.href = url;
    } else {
      consoleLog("RedirectToCompany-object ", store.getters.getRedirectUrl);
      window.parent.postMessage(store.getters.getRedirectUrl, "*");
    }
  }
};

const insertAfterProtocol = (url, domain) => {
  const protocol = "://";
  const t = url.split(protocol);
  return t[0] + protocol + domain + "-" + t[1];
};

export { redirectToCompany, insertAfterProtocol };
