import axios from "axios";
import { consoleLog } from "@/helpers/logger";

const axiosInstance = axios.create({
  // withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": process.env.VUE_APP_API_KEY,
  },
});
axiosInstance.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
    return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});

const generateMessageFromError = (error) => {
  let message = "";
  if (axios.isAxiosError(error)) {
    consoleLog(
      "generateMessageFromError ",
      `To json response ${error.toJSON()} ${error}`
    );
    if (error.response) {
      if (error.response && error.response.status === 503) {
        message = "Service is currently unavailable. Please try again later.";
      } else if (error.response.data && error.response.data != "") {
        // for our BE
        if (error.response.data.status && error.response.data.message) {
          message = error.response.data.status + ": ";
          message += error.response.data.message;
        } else if (
          error.response.data.statusCode &&
          error.response.data.message
        ) {
          message = error.response.data.statusCode + ": ";
          message += error.response.data.message;
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.statusText) {
        // general error message
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else {
        // root error
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    console.warn("Check instance: ", error instanceof Error);
    if (error instanceof Error) {
      if (error.message) {
        message = error.message;
      } else {
        console.error("error non message", error);
      }
    } else if (typeof error === "object") {
      console.warn("Yolo object ", error);
      const temp = JSON.stringify(error);
      if (temp) {
        message = temp;
      } else {
        console.error("Couldnt stringify error", error);
        // message = error;
      }
    } else {
      message = "Unidentified error: " + error;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};
export { generateMessageFromError };
export default {
  install(Vue) {
    Vue.prototype.$axiosInstance = axiosInstance;
  },
  instance: axiosInstance,
};
