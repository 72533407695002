import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import BankAppIcon from "@/components/partials/icons/BankAppIcon.vue";
import EmailIcon from "@/components/partials/icons/EmailIcon.vue";
import USSDIcon from "@/components/partials/icons/USSDIcon.vue";
import UploadIcon from "@/components/partials/icons/UploadIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      bankApp: {
        component: BankAppIcon,
      },
      upload: {
        component: UploadIcon,
      },
      ussd: {
        component: USSDIcon,
      },
      email: {
        component: EmailIcon,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#BFD131",
          lighten1: "#EFF4CB",
        },
        primaryFaded: "#BFD131",
        primaryButton: "#03082E",
        secondary: "#555555",
        accent: "#BFD131",
        error: "#FF4848",
        warning: colors.orange.base,
        info: colors.blue.base,
        success: "#BFD131",
        background: colors.grey.darken2,
        appBar: colors.grey.darken3,
        sideNav: colors.grey.darken1,
        appBg: "#F4F6F4",
      },
    },
  },
});
