export const goToPage = function (urlName, id, params = undefined) {
  if (this.$route.name == urlName) {
    console.error("Redundant navigation call - investigate");
    return;
  }
  this.$router.push({
    name: urlName,
    params: {
      id: id,
      ...params,
    },
  });
};

export const goToCancel = function ({ id, redirectedFrom }) {
  this.$router.push({
    name: "cancel",
    params: {
      id: id,
      redirectedFrom: redirectedFrom,
    },
  });
};
export const goToFatal = function (message) {
  if (this.$route.name !== "fatal") {
    this.$router.push({
      name: "fatal",
      params: {
        message: message,
      },
    });
  }
};
export const goToTimeout = function ({ id, message, fatal = false }) {
  if (this.$route.name != "timeout") {
    this.$router
      .push({
        name: "timeout",
        params: {
          id: id,
          message: message,
          fatal: fatal,
        },
      })
      .catch((e) => {
        console.log("Error nav", e);
      });
  } else {
    this.$route.params.message = message;
  }
};
