const consoleLog = (from, data) => {
  if (process.env.VUE_APP_DEBUG) {
    console.log(from, data);
  }
};

export { consoleLog };

export default {
  install(Vue) {
    Vue.prototype.$consoleLogger = consoleLog;
  },
};
