import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const authenticator = () => import("@/components/FlowAuthenticator.vue");
const chooseBank = () => import("@/components/ChooseBank.vue");
const credentials = () => import("@/components/BankCredentials.vue");
const Appwrapper = () => import("@/components/AppWrapper.vue");
const consent = () => import("@/components/AskConsent.vue");
const storedConsent = () => import("@/components/StoredConsent.vue");
const cancel = () => import("@/components/CancelCollection.vue");
const timeout = () => import("@/components/TimeoutPage.vue");
const dataCollectionCancelled = () =>
  import("@/components/CancelledCollection.vue");
const success = () => import("@/components/SuccessfulCollection.vue");
const fatal = () => import("@/components/FatalError.vue");
const welcome = () => import("@/components/WelcomePage.vue");
const selfService = () => import("@/components/SelfService.vue");
const TFG = () => import("@/components/TFG.vue");

const routes = [
  {
    path: "/self-service/:brand",
    name: "selfService",
    components: {
      default: selfService,
    },
    props: true,
  },
  {
    path: "/",
    name: "Appwrapper",
    components: {
      default: Appwrapper,
    },
    children: [
      {
        path: "tfg/custom",
        name: "tfg",
        components: {
          default: TFG,
        },
        props: true,
      },
      {
        path: "collections/:id",
        name: "home2",
        components: {
          default: welcome,
        },
      },
      {
        path: ":id",
        name: "home",
        components: {
          default: welcome,
        },
      },
      {
        path: "stored-consent/:id",
        name: "stored-consent",
        components: {
          default: storedConsent,
        },
        props: true,
      },
      {
        path: "consent/:id",
        name: "consent",
        components: {
          default: consent,
        },
        props: true,
      },
      {
        path: "/choose-bank/:id",
        name: "choose-bank",
        components: {
          default: chooseBank,
        },
        props: true,
      },
      {
        path: "/credentials/:id",
        name: "credentials",
        components: {
          default: credentials,
        },
        props: true,
      },
      {
        path: "/authenticator/:id",
        name: "authenticator",
        components: {
          default: authenticator,
        },
        props: true,
      },
      {
        path: "/cancel/:id",
        name: "cancel",
        component: cancel,
        props: true,
      },
      {
        path: "/timeout/:id",
        name: "timeout",
        component: timeout,
        props: true,
      },
      {
        path: "/cancelled/:id",
        name: "cancelled",
        component: dataCollectionCancelled,
        props: true,
      },
      {
        path: "/success/:id/:flow?",
        name: "success",
        components: {
          default: success,
        },
        props: true,
      },
      {
        path: "/fatal",
        name: "fatal",
        components: {
          default: fatal,
        },
      },
    ],
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
