import Vue from "vue";
import Vuex from "vuex";

import APP_STATE from "@/types.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    milestoneID: null,
    hasEnteredCredentials: false,
    captcha: false,
    milestones: [],
    selectedConsent: {},
    progressValue: 10,
    incorrectCredentials: false,
    consents: [],
    banks: [],
    challengeId: "",
    collections: null,
    appState: APP_STATE.NULL,
    progressBar: {
      isShown: false,
      currentValue: 0,
      consentStage: 0,
      wording: "fix",
      customText: null,
      connectWithTruAltColor: false,
    },
    reasons: [],
    selectedBank: {},
    errors: [],
    challenge: [],
    challengeAccounts: {},
    challengeOTP: {},
    lastStatus: {
      timestamp: "",
      code: 0,
      message: "",
      challengeId: "",
    },
    lastStatusCode: 0,
    banksStatuses: null
  },
  getters: {
    getBankStatuses: (state) => {
      return state.banksStatuses;
    },
    getLastStatusCode: (state) => {
      return state.lastStatusCode;
    },
    getLastStatus: (state) => {
      return state.lastStatus;
    },
    getRedirectUrl: (state, getters) => {
      let temp = "status=" + getters.getLastStatusCode;
      if (getters.getCollections == null) return null;
      const regex = /event:/;
      let url = state.collections.redirectUrl;
      if (typeof url == "string") {
        if (regex.test(url)) {
          const split = url.split("event:");
          const vars = split[1].split("&");
          let obj = {
            collection: getters.getCollections.id,
            status: getters.getLastStatusCode,
          };
          for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            obj = {
              ...obj,
              [pair[0]]: pair[1],
            };
            // pairs.push(decodeURIComponent(pair[1]));
          }
          return obj;
        } else {
          const timerArr = url.split("@");
          console.log("Timer Arr", timerArr);
          if (timerArr.length > 1) {
            const time = timerArr[1];
            if (time) {
              url = timerArr[0];
            }
          }
          if (url.includes("?")) {
            url += "&" + temp;
          } else {
            url += "?" + temp;
          }
          if (timerArr.length > 1) {
            const time = timerArr[1];
            url += "@" + time;
          }
          if (url.includes("http")) {
            return url;
          } else {
            return "https://" + url;
          }
        }
      }
    },
    getMilestoneID: (state) => {
      return state.milestoneID;
    },
    getHasEnteredCredentials: (state) => {
      return state.hasEnteredCredentials;
    },
    getCaptchaRequired: (state) => {
      return state.captcha;
    },
    getMilestones: (state) => {
      return state.milestones;
    },
    getDataProvider: (state) => {
      if (state.selectedBank != {}) {
        return state.selectedBank;
      } else {
        if (state.collections != null) {
          if (state.collections.dataProvider != undefined) {
            return state.collections.dataProvider;
          }
        }
      }
      return [];
    },
    getBanks: (state) => {
      return state.banks;
    },
    getBrand: (state) => {
      if (state.collections != null) {
        return state.collections.brand;
      } else {
        return {};
      }
    },
    getConsents: (state) => {
      return state.consents;
    },
    getSelectedConsent: (state) => {
      return state.selectedConsent;
    },
    getLoading: (state) => {
      return state.appState;
    },
    getProgressBar: (state) => {
      return state.progressValue;
    },

    getCollections: (state) => {
      return state.collections;
    },
    getSelectedBank: (state) => {
      if (state.collections != null) {
        if (state.collections.dataProvider != undefined) {
          return state.collections.dataProvider;
        } else {
          return state.selectedBank;
        }
      }
    },
    getReasons: (state) => {
      return state.reasons;
    },
    getChallengeId: (state) => {
      return state.challengeId;
    },
    getChallenge: (state) => {
      return state.challenge;
    },
    getChallengeOTP: (state) => {
      return state.challengeOTP;
    },
    getChallengeAccounts: (state) => {
      return state.challengeAccounts;
    },
    getValidCredentialsStatus: (state) => {
      return state.incorrectCredentials;
    },
  },
  mutations: {
    SET_PROGRESS_BAR: (state, payload) => {
      state.progressValue = payload;
    },
    SET_INITIAL_STATE(state, toState) {
      state.collections = toState;
    },
    SET_LOADING_STATE(state, { appState, errors }) {
      if (errors != null) {
        errors.forEach((error) => {
          state.errors.push(error);
        });
      }
      state.appState = appState;
    },
    SET_SELECTED_BANK(state, toState) {
      state.selectedBank = toState;
    },
    SET_BANK_STATUSES(state, toState) {
      state.banksStatuses = toState;
    },
    SET_BANKS(state, toState) {
      state.banks = toState;
    },
    SET_REASONS(state, toState) {
      state.reasons = toState;
    },
    SET_CHALLENGE_ID(state, toState) {
      state.challengeId = toState;
    },
    SET_CONSENTS(state, consents) {
      state.consents = consents;
    },
    SET_SELECTED_CONSENT(state, consent) {
      state.selectedConsent = consent;
    },
    SET_MILESTONES(state, milestones) {
      state.milestones = milestones;
    },
    SET_CHALLENGE(state, payload) {
      if (payload == null) {
        state.challengeOTP = {};
      } else if (payload.type == "account-selection") {
        state.challengeAccounts = payload;
      } else if (
        payload.type == "otp" ||
        payload.type == "password" ||
        payload.type == "captcha" ||
        payload.type == "qrcode"
      ) {
        state.challengeOTP = {
          ...payload,
          // pauseTime: 120000,
        };
      }
    },
    SET_CREDENTIALS_STATUS(state, payload) {
      state.incorrectCredentials = payload;
    },
    SET_CAPTCHA(state, payload) {
      state.captcha = payload;
    },
    SET_CREDENTIALS_ENTERED(state, payload) {
      state.hasEnteredCredentials = payload;
    },
    SET_MILESTONE_ID(state, payload) {
      state.milestoneID = payload;
    },
    SET_LAST_STATUS_CODE(state, payload) {
      state.lastStatusCode = payload;
    },
    SET_LAST_STATUS(state, payload) {
      console.log("Changed last status", payload);
      state.lastStatus = payload;
    },
  },
  actions: {},
  modules: {},
});
