import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./css/Main.css";
import vuetify from "./plugins/vuetify";
import axios, { generateMessageFromError } from "./plugins/axios";
import ConsoleLogger, { consoleLog } from "./helpers/logger";
// import "roboto-fontface/css/roboto/roboto-fontface.css";
// import "@mdi/font/css/materialdesignicons.css";

import Hotjar from "vue-hotjar";

import footer from "@/components/partials/AppFooter.vue";
import APP_STATE from "@/types.js";
// import { VueReCaptcha } from "vue-recaptcha-v3";

// For more options see below
// Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA });
Vue.use(ConsoleLogger);

Vue.config.devtools = true;
Vue.config.productionTip = false;

Vue.use(Hotjar, {
  id: "2167782",
  isProduction: process.env.NODE_ENV == "production" ? true : false,
});

Vue.component("accred-footer", footer);
Vue.filter("iconFilter", (val) => {
  switch (val) {
    case "id-verification": {
      return "mdi-card-account-details-outline";
    }
    case "transaction-history": {
      return "mdi-clock-outline";
    }
    case "bank-statement": {
      return "mdi-file-document-outline";
    }
    case "beneficiaries": {
      return "mdi-account-group-outline";
    }
    case "address": {
      return "mdi-home-outline";
    }
    case "contact-info": {
      return "mdi-phone-in-talk-outline";
    }
    case "personal-info": {
      return "mdi-account-circle-outline";
    }
    case "account-info": {
      return "mdi-bank-outline";
    }
    case "strike-date-predictor": {
      return "mdi-calendar-month";
    }
    case "affordability": {
      return "mdi-wallet-outline";
    }
    case "business-categorisation": {
      return "mdi-briefcase-variant-outline";
    }
    case "debit-order-categorisation": {
      return "mdi-priority-low";
    }
    case "account-verification": {
      return "mdi-check-decagram-outline";
    }
    case "mandated-transactions": {
      return "";
    }
  }
  console.log("Test ", val);
  return "mdi-alarm";
});

// [client].truidconnect.io
// [client]-api.truidconnect.io
// [client]-images.truidconnect.io
let baseUrl = process.env.VUE_APP_BASE_API_URL;
// let temp = "rainbow.truidconnect.io/";
if (window.location.hostname != "localhost") {
  // if (temp != "localhost") {
  const split = "://";
  const items = window.location.hostname.split(".");
  if (items[0] != "www" && items[1] == "truidconnect") {
    //modify url for apis / images
    const t = baseUrl.split(split);
    baseUrl = t[0] + split + items[0] + "-" + t[1];
  }
}
consoleLog("main-baseUrl", baseUrl);
const config = {
  baseUrl: baseUrl,
  mode: process.env.VUE_APP_MODE,
  apiKey: process.env.VUE_APP_API_KEY,
  links: {
    reasons: "reasons",
    collection: "collections",
    consents: "consents",
    bank: "banks",
  },
  APP_STATE: APP_STATE,
};
Vue.prototype.$config = config;
Vue.use(axios);

const mixin = {
  methods: {
    async setMilestone(route, milestones) {
      if (milestones !== undefined) {
        if (milestones.length > 0) {
          if (milestones[milestones.length - 1].tag == route) {
            return;
          }
        }
      }
      try {
        const resp = await this.$axiosInstance.post(
          `${this.$config.baseUrl}/${this.$config.links.collection}/${this.$route.params.id}/milestones`,
          {
            tag: route,
          },
          { retry: 2, retryDelay: 3000 }
        );
        // console.log("Splitting headers here. ", resp.headers.location);
        var milestoneID = String(resp.headers.location).split("milestones/")[1];
        // console.log("After split  ", milestoneID);
        this.$store.commit("SET_MILESTONE_ID", milestoneID);
        return { tag: route, id: milestoneID };
      } catch (err) {
        this.goToTimeout({ id: this.$route.params.id, message: err });
      }
    },

    async getLastMilestone() {
      try {
        const resp = await this.$axiosInstance.get(
          `${this.$config.baseUrl}/${this.$config.links.collection}/${this.$route.params.id}/milestones/${this.currentID}`,
          { retry: 2, retryDelay: 3000 }
        );
        if (resp.data.length == 0) {
          //no milestone
        } else {
          return resp.data;
        }
        // console.log("--------------------- Resopnse on get last milestone ----------------", resp);
      } catch (err) {
        this.goToTimeout({
          id: this.$route.params.id,
          message: generateMessageFromError(err),
        });
      }
    },
    async goBackToPrevMilestone() {
      const resp = await this.getLastMilestone();
      // console.log("received last milestone", resp)
      if (resp !== undefined) {
        // console.log("Resp not undefined", resp)
        await this.setMilestone(
          resp.tag,
          store.getters.getCollections.milestones
        );
        this.goToPage(resp.tag, this.$route.params.id);
      }
    },
    async wait(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    async getCurrentMilestone() {
      try {
        const resp = await this.$axiosInstance.get(
          `${this.$config.baseUrl}/${this.$config.links.collection}/${this.$route.params.id}/milestones`,
          { retry: 2, retryDelay: 3000 }
        );
        if (resp.data.length == 0) {
          return await this.setMilestone(
            "home",
            store.getters.getCollections.milestones
          );
        } else {
          return resp.data;
        }
      } catch (err) {
        this.goToTimeout({
          id: this.$route.params.id,
          message: generateMessageFromError(err),
        });
      }
    },
  },
  computed: {
    currentID() {
      // console.log("Store getter current id ", store.getters['getMilestoneID']);
      return store.getters["getMilestoneID"];
    },
  },
};

Vue.mixin(mixin);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
