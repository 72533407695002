<template>
  <v-row justify="center" class="mt-0" align-self="end">
    <v-col cols="10" class="py-0">
      <v-container class="pt-1">
        <v-row class="mb-0 pb-0" justify="center">
          <v-col class="mb-0 pb-0" cols="auto">
            <v-img
              contain
              :width="105"
              :src="require('@/assets/truidAccreditation.png')"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-2" v-if="getBrand" justify="center">
          <v-col cols="11" class="mt-0 pt-0">
            <p style="font-size: 7px" class="font-weight-bold text-wrap">
              <span class="primary--text">{{ getBrand.name }} </span
              ><span v-html="accredNumber"></span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getBrand"]),
    accredNumber() {
      var temp = String(this.getBrand.accreditationNr);
      // var temp = String('1231231981932080sajdo-sdoiasdasd-asdas-dasdasdhask');
      var size = 25;
      return temp.length < size
        ? "Accreditation #" + temp
        : "<br/>" +
            "Accreditation #" +
            temp.substr(0, size) +
            temp.substr(size);
    },
  },
};
</script>

<style scoped>
nobr {
  white-space: nowrap;
  hyphens: none;
}
</style>
