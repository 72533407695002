<template>
  <v-app>
    <v-banner v-if="$config.mode !== 'production'" class="px-2">
      <div class="text-body-1">
        Please note you are accessing a
        <b
          >truID <u class="text-uppercase">{{ $config.mode }}</u></b
        >
        environment.
      </div>
    </v-banner>
    <router-view
      v-on:refreshCollToggle="refreshCollection"
      class="view fill-height"
    ></router-view>
  </v-app>
</template>

<script>
// Vuetify ME
import { mapGetters } from "vuex";
import {
  goToCancel,
  goToPage,
  goToTimeout,
  goToFatal,
} from "./utils/navigation.js";
import { insertAfterProtocol } from "@/helpers/redirect";
import { generateMessageFromError } from "./plugins/axios.js";

export default {
  computed: {
    ...mapGetters([
      "getCollections",
      "getLoading",
      "getConsents",
      "getBanks",
      "getBankStatuses",
    ]),
  },
  async mounted() {
    const rep = await this.$axiosInstance.get(
      `${process.env.BASE_URL}Banks.json`
    );
    console.log("Loaded bank statuses? : ", rep.data);
    this.$store.commit("SET_BANK_STATUSES", rep.data);

    console.log("Vuex bank statuses? : ", this.getBankStatuses);
    ;
  },
  methods: {
    goToPage,
    goToTimeout,
    goToFatal,
    goToCancel,
    async refreshCollection() {
      await this.loadCollection();
    },
    async loadConsents() {
      try {
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADING,
        });
        const rep = await this.$axiosInstance.get(
          `${this.$config.baseUrl}/${this.$config.links.collection}/${this.$route.params.id}/consents`,
          { retry: 2, retryDelay: 3000 }
        );
        this.$store.commit("SET_CONSENTS", rep.data);
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADED,
        });
        if (
          rep.data.length == 0 &&
          this.getCollections.dataProvider == undefined
        ) {
          await this.setMilestone(
            "choose-bank",
            this.getCollections.milestones
          );
          this.goToPage("choose-bank", this.$route.params.id);
        } else if (
          rep.data.length == 0 &&
          this.getCollections.dataProvider != undefined
        ) {
          await this.setMilestone(
            "credentials",
            this.getCollections.milestones
          );
          this.goToPage("credentials", this.$route.params.id);
        } else {
          await this.setMilestone(
            "stored-consent",
            this.getCollections.milestones
          );
        }
        return rep.data;
      } catch (err) {
        this.goToTimeout({
          id: this.$route.params.id,
          message: generateMessageFromError(err),
        });
        return Promise.reject(err);
      } finally {
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADED,
        });
      }
    },

    async loadCollection() {
      try {
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADING,
        });
        if (!this.$route.params.id)
          this.$consoleLogger("Undefined Route", window.location.hostname);
        const resp = await this.$axiosInstance.get(
          `${this.$config.baseUrl}/${this.$config.links.collection}/${this.$route.params.id}`,
          { retry: 2, retryDelay: 3000 }
        );

        if (window.location.hostname != "localhost") {
          this.$consoleLogger(
            "App-loadCollection-window",
            window.location.hostname
          );
          const items = window.location.hostname.split(".");
          this.$consoleLogger("App-loadCollection-items", items);
          if (items[0] != "www" && items[1] == "truidconnect") {
            //do custom
            this.$consoleLogger(
              "App-loadCollection-window",
              "its on truidconnect"
            );
            if (resp.data.brand) {
              this.$consoleLogger(
                "App-loadCollection-hasBrand",
                resp.data.brand
              );
              if (resp.data.brand.logoUrl) {
                const newImg = insertAfterProtocol(
                  resp.data.brand.logoUrl,
                  items[0]
                );
                resp.data.brand.logoUrl = newImg;
              }
            }
            if (resp.data.dataProvider) {
              if (resp.data.dataProvider.primaryLogoUrl) {
                const newImg = insertAfterProtocol(
                  resp.data.dataProvider.primaryLogoUrl,
                  items[0]
                );
                resp.data.dataProvider.primaryLogoUrl = newImg;
              }
              if (resp.data.dataProvider.secondaryLogoUrl) {
                const newImg = insertAfterProtocol(
                  resp.data.dataProvider.secondaryLogoUrl,
                  items[0]
                );
                resp.data.dataProvider.secondaryLogoUrl = newImg;
              }
            }
          }
        }

        this.$store.commit("SET_INITIAL_STATE", resp.data);
      } catch (err) {
        this.goToTimeout({
          id: this.$route.params.id,
          message: generateMessageFromError(err),
          fatal: true,
        });
        return Promise.reject(err);
      } finally {
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADED,
        });
      }
    },
    async loadBanks() {
      try {
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADING,
        });
        const collectionId = this.$route.params.id;
        const resp = await this.$axiosInstance.get(
          `${this.$config.baseUrl}/${this.$config.links.collection}/${collectionId}/data-providers`,
          { retry: 2, retryDelay: 3000 }
        );

        if (resp.data.length == 0) {
          return;
        }
        if (window.location.hostname != "localhost") {
          this.$consoleLogger("App-loadBanks-window", window.location.hostname);
          const items = window.location.hostname.split(".");
          this.$consoleLogger("App-loadBanks-items", items);
          if (items[0] != "www" && items[1] == "truidconnect") {
            //do custom
            resp.data.forEach((provider) => {
              if (provider.primaryLogoUrl) {
                const newImg = insertAfterProtocol(
                  provider.primaryLogoUrl,
                  items[0]
                );
                provider.primaryLogoUrl = newImg;
              }
              if (provider.secondaryLogoUrl) {
                const newImg = insertAfterProtocol(
                  provider.secondaryLogoUrl,
                  items[0]
                );
                provider.secondaryLogoUrl = newImg;
              }
            });
          }
        }
        this.$store.commit("SET_BANKS", resp.data);
      } catch (err) {
        this.goToTimeout({
          id: this.$route.params.id,
          message: generateMessageFromError(err),
        });
        // this.$store.commit("SET_LOADING_STATE", {
        //   appState: this.$config.APP_STATE.FAILED,
        //   errors: [err],
        // });
      } finally {
        this.$store.commit("SET_LOADING_STATE", {
          appState: this.$config.APP_STATE.LOADED,
        });
      }
    },
  },
  watch: {
    getCollections: async function anon(val) {
      if (val != null) {
        const currMilestone = await this.getCurrentMilestone();
        // console.log("current milestone ", currMilestone);
        if (currMilestone !== undefined) {
          // console.log("Set milestone from watchers ", currMilestone);
          this.$store.commit("SET_MILESTONE_ID", currMilestone.id);
          if (this.$route.name != currMilestone.tag) {
            if (
              currMilestone.tag == "stored-consent" &&
              this.$route.name == "authenticator"
            ) {
              return;
            }
            if (
              currMilestone.tag == "consent" &&
              this.$route.name == "stored-consent"
            ) {
              return;
            }
            this.goToPage(currMilestone.tag, this.$route.params.id);
          } else {
            if (val.statuses) {
              // console.log("Res : ", val.statuses);
              if (val.statuses.length > 0) {
                const code = val.statuses[val.statuses.length - 1].code;
                if (
                  code == 2 ||
                  (code > 1000 &&
                    code != 1010 &&
                    code != 3000 &&
                    code != 5000 &&
                    code != 9000 &&
                    code != 9006 &&
                    code != 9001 &&
                    code != 2000)
                ) {
                  this.goToPage("authenticator", this.$route.params.id);
                }
              }
            }
          }
        }
      }
    },
    $route: async function () {
      if (this.$route.name == "selfService" || this.$route.name == "tfg") {
        // console.log("Ignore self service lol");
      } else if (this.$route.params.id == null) {
        this.goToFatal("no-id");
      } else {
        if (
          this.getCollections == null ||
          this.getCollections.id != this.$route.params.id
        ) {
          await this.loadCollection();
        }
        if (this.$route.name == "choose-bank") {
          await this.loadCollection();
          if (this.getBanks.length == 0) {
            await this.loadBanks();
          }
        } else if (this.$route.name == "stored-consent") {
          await this.loadCollection();
          if (this.getCollections.milestones.length > 0) {
            await this.loadConsents();
          }
          await this.loadBanks();
        } else if (this.$route.name == "credentials") {
          await this.loadCollection();
        } else if (this.$route.name == "success") {
          await this.loadCollection();
        } else if (this.$route.name == "consent") {
          await this.loadCollection();
        }
      }
    },
  },
};
</script>
